import classNames from "classnames";
import { graphql } from "gatsby";
import React, { Component } from "react";
import Logo from "../assets/LogoWatermark.svg";
import {
  Layout,
  OurWorkSection,
  SEOTags,
  WhatWeDoSection,
  WhyMahusaiGlobalSection,
  WorkingWithUsSection,
} from "../components";
import * as styles from "../styles/whatWeDo.module.scss";

class WhatWeDo extends Component {
  render() {
    const ourWork = this.props.data.portfolioMdx.nodes.map((item, index) => ({
      text: item.childMdx.body,
      industry: item.childMdx.frontmatter.title,
      publicURL: this.props.data.portfolioIcons.nodes[index].publicURL,
    }));

    return (
      <Layout layoutClassName="whatWeDo-page">
        <div className={styles.whatWeDopage}>
          <section className={styles.hero}>
            {/* <div className={`${styles.heroAnimation} ${styles.computer}`}>
              <lottie-player
                src="/lottie/Home-hero.json"
                autoplay
                loop
                preserveAspectRatio="xMidYMid slice"
                style={{
                  height: "-webkit-fill-available",
                  width: "-webkit-fill-available",
                }}
              ></lottie-player>
            </div>
            <div className={`${styles.heroAnimation} ${styles.mobile}`}>
              <lottie-player
                src="/lottie/Home-mobile-hero.json"
                autoplay
                loop
                preserveAspectRatio="xMidYMid slice"
                style={{
                  height: "-webkit-fill-available",
                  width: "-webkit-fill-available",
                }}
              ></lottie-player>
            </div> */}
            <Logo className={classNames(styles.watermark, styles.mobile)} />
            <h6 className={styles.header}>WHAT WE DO</h6>
            <h1 className={styles.title}>
              Over 12 years of software design and build experience
            </h1>
            <p className={styles.details}>
              We build software across all industry verticals delivering fast,
              scalable solutions that place user experience and business value
              in your hands.
            </p>
          </section>
          <WhyMahusaiGlobalSection />
          <WorkingWithUsSection />
          <WhatWeDoSection />
          <OurWorkSection data={ourWork} hideLink />
          {/* <WhyMahusaiGlobalSection/> */}
        </div>
      </Layout>
    );
  }
}
export default WhatWeDo;

export const Head = ({ location }) => (
  <SEOTags title={"What we do | Mahusai Global"} pathname={location.pathname} />
);

export const query = graphql`
  query {
    portfolioIcons: allFile(
      filter: {
        relativeDirectory: { regex: "/assets/portfolio/(.*)/" }
        extension: { eq: "svg" }
      }
      sort: { fields: relativeDirectory, order: ASC }
    ) {
      nodes {
        publicURL
      }
    }
    portfolioMdx: allFile(
      filter: {
        relativeDirectory: { regex: "/assets/portfolio/(.*)/" }
        extension: { eq: "mdx" }
      }
      sort: { fields: relativeDirectory, order: ASC }
    ) {
      nodes {
        childMdx {
          body
          frontmatter {
            title
          }
        }
      }
    }
  }
`;
